import { COUNTRY_TYPE } from '@purple/common/constants/locationTypes'

// 게임목록 카테고리 타입
export const GAME_CATEGORY_TYPE = {
  PC: 'pc',
  MOBILE: 'mobile'
}

// 게임목록 서브링크 타입
export const GAME_SUB_LINK_TYPE = {
  NSHOP: 'nshop',
  APPLE: 'apple',
  GOOGLE: 'google',
  ONE_STORE: 'onestore',
  NEWS: 'news' // SIE 게임 소식 게시판
}

const CONTI_COUNTRY_GROUP_TW = [
  COUNTRY_TYPE.TW,
  COUNTRY_TYPE.HK,
  COUNTRY_TYPE.MO
]
const CONTI_COUNTRY_GROUP_JP = [COUNTRY_TYPE.JP]
const CONTI_COUNTRY_GROUP_US = [
  COUNTRY_TYPE.US, //미국
  COUNTRY_TYPE.AA, //Unknown
  COUNTRY_TYPE.CA, //캐나다
  COUNTRY_TYPE.DE, // 독일
  COUNTRY_TYPE.GB, //영국
  COUNTRY_TYPE.FR, //프랑스
  COUNTRY_TYPE.IT, //이탈리아
  COUNTRY_TYPE.ES, //스페인
  COUNTRY_TYPE.SE, //스웨덴
  COUNTRY_TYPE.IE, //아일랜드
  COUNTRY_TYPE.PT, //포르투칼
  COUNTRY_TYPE.FI, //핀란드
  COUNTRY_TYPE.DK, //덴마크
  COUNTRY_TYPE.CH, //스위스
  COUNTRY_TYPE.BR, //브라질
  COUNTRY_TYPE.CL, //칠레
  COUNTRY_TYPE.AR, //아르헨티나
  COUNTRY_TYPE.PE, //페루
  COUNTRY_TYPE.MX, //멕시코
  COUNTRY_TYPE.NO, //노르웨이
  COUNTRY_TYPE.CO, //콜롬비아
  COUNTRY_TYPE.EC, //에콰도르
  COUNTRY_TYPE.AT, //오스트리아
  COUNTRY_TYPE.NZ, //뉴질랜드
  COUNTRY_TYPE.CZ, //체코
  COUNTRY_TYPE.BE, //벨기에
  COUNTRY_TYPE.NL, //네덜란드
  COUNTRY_TYPE.TR, //터키(튀르키예)
  COUNTRY_TYPE.RO, //루마니아
  COUNTRY_TYPE.GR, //그리스
  COUNTRY_TYPE.LT, //리투아니아
  COUNTRY_TYPE.AU //호주
]
const CONTI_COUNTRY_GROUP_KR = [
  COUNTRY_TYPE.KR, //한국
  COUNTRY_TYPE.ID, //인도네시아
  COUNTRY_TYPE.TH, //태국
  COUNTRY_TYPE.SA, //사우디아라비아
  COUNTRY_TYPE.AE, //아랍에미리트
  COUNTRY_TYPE.QA, //카타르
  COUNTRY_TYPE.RU, //러시아
  COUNTRY_TYPE.UA, //우크라이나
  COUNTRY_TYPE.PL, //폴란드
  COUNTRY_TYPE.BY, //벨라루스
  COUNTRY_TYPE.IL, //이스라엘
  COUNTRY_TYPE.KW, //쿠웨이트
  COUNTRY_TYPE.KZ, //카자흐스탄
  COUNTRY_TYPE.UZ, // 우즈베키스탄
  COUNTRY_TYPE.AZ, //아제르바이잔
  COUNTRY_TYPE.GE, //조지아
  COUNTRY_TYPE.MD, //몰도바
  COUNTRY_TYPE.AM, //아르메니아
  COUNTRY_TYPE.MY, //말레이시아
  COUNTRY_TYPE.SG, //싱가포르
  COUNTRY_TYPE.PH //필리핀
]

// 콘티 국가 그룹(KEY: 각 그룹별 대표국가)
export const CONTI_COUNTRY_GROUP = {
  TW: CONTI_COUNTRY_GROUP_TW,
  JP: CONTI_COUNTRY_GROUP_JP,
  US: CONTI_COUNTRY_GROUP_US,
  KR: CONTI_COUNTRY_GROUP_KR,
  // COUNTRY_TYPE 에 정의되어 있는 국가중 TW,JP,US,KR 제외한 나머지 국가
  ETC: Object.values(COUNTRY_TYPE).filter(
    (country) =>
      !(
        CONTI_COUNTRY_GROUP_TW.includes(country) ||
        CONTI_COUNTRY_GROUP_JP.includes(country) ||
        CONTI_COUNTRY_GROUP_US.includes(country) ||
        CONTI_COUNTRY_GROUP_KR.includes(country)
      )
  )
}

// 게임 플랫폼
export const GAME_PLATFORM_TYPE = {
  PC: 'pc',
  MOBILE: 'mobile',
  SIE: 'SIE'
}

// 상품 타입
export const GOODS_TYPE = {
  EDITION: 'EDITION',
  DLC: 'DLC',
  BUNDLE: 'BUNDLE' // 묶음상품
}

// 게임 상세 타입
export const GAME_VIEW_TYPE = {
  BASIC: 'BASIC',
  MAIN: 'MAIN'
}

// shop에 전달하는 퍼플 앱그룹코드
export const PURPLE_APP_GROUP_CODE = 'pps'

// 게임상세 메뉴 타입
export const GAME_MENU_TYPE = {
  ACHIEVEMENT: 'achievement',
  REVIEW: 'review',
  BOARD: 'board'
}

// 이메일 광고 수신 동의 상태
export const AD_CONSENT_STATUS = {
  DISAGREE: 'DISAGREE',
  AGREE: 'AGREE',
  REJECT: 'REJECT'
}
