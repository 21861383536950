import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Input } from '@purple/design/react'
import { useTranslation } from 'react-i18next'

// hooks
import { useUpdateAdConsent } from 'hooks/store/usePurchaseAsync'

// rtk
import { useGetAdConsentQuery } from 'store/services/query/purchaseQuery'

// Component
import LayerWrapper from 'components/Popup/components/common/LayerWrapper'
import LayerBody from 'components/Popup/components/common/LayerBody'
import LayerHeader from 'components/Popup/components/common/LayerHeader'
import { LayerFooter } from 'components/Popup/components/common/LayerFooter'

const Top = styled.p`
  padding: 24px 16px;
  margin: 0 8px;
  text-align: center;
  ${({ theme }) => theme.typography.body3};
  color: ${({ theme }) => theme.color.glyphs250};
`

const InputWrap = styled.div`
  padding: 0 8px;
  & > p {
    ${({ theme }) => theme.typography.caption1};
    color: ${({ theme }) => theme.color.glyphs300};
    margin: 20px 0 24px;
  }
`
const Label = styled.label`
  display: block;
  padding: 8px 0;
  ${({ theme }) => theme.typography.body3};
  color: ${({ theme }) => theme.color.glyphs300};
`

// 광고성 정보 수신동의 팝업
function AdConsentPopup(props) {
  const { layerClose } = props
  const { t } = useTranslation()
  const { updateAdConsent } = useUpdateAdConsent()
  const { data: consentData } = useGetAdConsentQuery()

  // 수신 동의 or 거부
  const updateAdConsentStaus = useCallback(
    (isAgree) => {
      updateAdConsent({ agree: isAgree })
      layerClose()
    },
    [layerClose, updateAdConsent]
  )

  return (
    <LayerWrapper>
      <LayerHeader
        title={t('Consent to receive advertising information')}
        onClickClose={layerClose}
      />

      <LayerBody
        style={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}
      >
        <Top>
          {t(
            'We will send you various benefits and information about Purple and NCSOFT.'
          )}
        </Top>
        <InputWrap>
          <Label>{t('My email address to receive')}</Label>
          <Input type="text" value={consentData.email} disabled={true} />
          <p>
            {t('The company uses the email address entered by the customer', {
              year: 2
            })}
          </p>
        </InputWrap>
      </LayerBody>
      <LayerFooter
        // 수신 거부 버튼 추가 및 거부 동선 추가 필요
        leftButtonLabel={t('unsubscribe')}
        rightButtonLabel={t('opt in')}
        onClickLeft={() => updateAdConsentStaus(false)}
        onClickRight={() => updateAdConsentStaus(true)}
      />
    </LayerWrapper>
  )
}

export default AdConsentPopup
