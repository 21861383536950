import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
// RTK
import {
  useAddCartItemMutation,
  useAddFavoriteItemMutation,
  useDeleteFavoriteItemMutation,
  useDeleteAllFavoriteItemMutation,
  useCreateOrderFormMutation,
  purchaseQuery,
  useLazyGetAdConsentQuery,
  useUpdateAdConsentMutation
} from 'store/services/query/purchaseQuery'
import { useLazyCheckLoginUserQuery } from 'store/services/query/npQuery'

// utils
import { isPurpleApp } from '@purple/common/utils/checkUtility'
import { STORE_PATH } from 'routes/store/path'
import { moveOrderView } from 'routes/store/locations'
import {
  openRegisterEmailPopup,
  openParentAgreementPopup,
  openIdentifyPopup,
  commonError
} from 'utils/purchaseUtility'
import { commonSessionError } from 'utils/appUtility'
import { dateFormat } from '@purple/common/utils/dayjsUtility'
import { mobileScheme } from '@purple/common/utils/mobileUtility'

// Constants
import { AD_CONSENT_STATUS } from 'constants/storeTypes'

// components
import useToast from 'hooks/common/useToast'
import { confirmPopup, adConsentPopup } from 'components/Popup'

// 로그인 유저정보 조회
function useCheckLoginUser() {
  const [checkLoginUserTrigger] = useLazyCheckLoginUserQuery()

  const checkLoginUser = useCallback(async () => {
    try {
      await checkLoginUserTrigger().unwrap()
    } catch (error) {
      console.log(error)
    }
  }, [checkLoginUserTrigger])

  return {
    checkLoginUser
  }
}

// 이메일 광고 수신 동의 여부 조회
function useGetAdConsent() {
  const [getAdConsentTrigger] = useLazyGetAdConsentQuery()

  const getAdConsent = useCallback(async () => {
    try {
      const res = await getAdConsentTrigger().unwrap()
      return res
    } catch (error) {
      throw error
    }
  }, [getAdConsentTrigger])

  return {
    getAdConsent
  }
}

// 이메일 광고 수신 동의 업데이트
function useUpdateAdConsent() {
  const { t } = useTranslation()
  const { onInfoToast } = useToast()

  const [updateAdConsent] = useUpdateAdConsentMutation()

  const fetchData = useCallback(
    async (params) => {
      try {
        const res = await updateAdConsent(params).unwrap()
        if (params?.agree) {
          // 동의 성공 안내 토스트
          onInfoToast({
            message: `<span class='subtitle'>${dateFormat(
              res.agree_date
            )}</span>${t('agreed to receive advertising emails')}`
          })
        } else {
          // 거부 성공 안내 토스트
          onInfoToast({
            message: `<span class='subtitle'>${dateFormat(
              res.agree_date
            )}</span>${t('The withdrawal of consent has been completed')}`
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    [onInfoToast, updateAdConsent, t]
  )

  return {
    updateAdConsent: fetchData
  }
}

// 찜 추가
function useAddFavoriteItem() {
  const { t } = useTranslation()
  const { onInfoToast } = useToast()
  const { getAdConsent } = useGetAdConsent()
  const [addFavoriteItem] = useAddFavoriteItemMutation()

  // 찜 추가 요청
  const fetchData = useCallback(
    async (id) => {
      try {
        await addFavoriteItem({ id }).unwrap()
        onInfoToast({
          message: t('Added to wishlist'),
          iconName: 'HeartFilled'
        })
      } catch (error) {
        throw error
      }
    },
    [addFavoriteItem, onInfoToast, t]
  )

  const addProcess = useCallback(
    async (id) => {
      try {
        if (!id) return
        await fetchData(id)

        const { status, email } = await getAdConsent()

        if (!email) {
          openRegisterEmailPopup()
        } else if (status === AD_CONSENT_STATUS.DISAGREE) {
          // 수신동의 미동의 상태인 경우 수신동의 팝업
          adConsentPopup()
        }
      } catch (error) {
        // 세션 만료
        if (error?.status === 401) {
          commonSessionError()
        } else {
          commonError()
        }
      }
    },
    [getAdConsent, fetchData]
  )

  return {
    addFavoriteItem: addProcess
  }
}

// 찜 삭제
function useDeleteFavoriteItem() {
  const { t } = useTranslation()
  const { onInfoToast } = useToast()
  const [deleteFavoriteItem] = useDeleteFavoriteItemMutation()
  const [deleteAllFavoriteItem] = useDeleteAllFavoriteItemMutation()

  // 찜 삭제 요청
  const deleteData = useCallback(
    async (id) => {
      try {
        await deleteFavoriteItem({ id }).unwrap()
        onInfoToast({
          message: t('removed from wishlist'),
          iconName: 'Heart'
        })
      } catch (error) {
        // 세션 만료
        if (error?.status === 401) {
          commonSessionError()
        } else {
          commonError()
        }
      }
    },
    [deleteFavoriteItem, onInfoToast, t]
  )

  // 찜 전체 삭제 요청
  const deleteAllData = useCallback(async () => {
    try {
      await deleteAllFavoriteItem().unwrap()
      onInfoToast({
        message: t('removed from wishlist'),
        iconName: 'Heart'
      })
    } catch (error) {
      if (error?.status === 401) {
        commonSessionError()
      } else {
        commonError()
      }
    }
  }, [deleteAllFavoriteItem, onInfoToast, t])

  return {
    deleteFavoriteItem: deleteData,
    deleteAllFavoriteItem: deleteAllData
  }
}

// 장바구니 추가
function useAddCartItem() {
  const { t } = useTranslation()
  const history = useHistory()
  const { onInfoToast } = useToast()
  const [addCartItem] = useAddCartItemMutation()

  // 장바구니 추가 요청
  const fetchData = useCallback(
    async (params) => {
      try {
        await addCartItem(params).unwrap()
        confirmPopup({
          msg: `${t('added to cart')}<br/>${t(
            'would you like to go to cart?'
          )}`,
          type: 'confirm',
          rightButtonLabel: t('go'),
          onRight: () => {
            history.push(STORE_PATH.CART)
          }
        })
      } catch (error) {
        const { status, data } = error
        if (status === 401) {
          commonSessionError()
        } else if (status === 403 && data.error) {
          switch (data.error) {
            // 실명 인증 정보 없음
            case '47640':
              confirmPopup({
                msg: t('you must verify your identity to use the service'),
                type: 'confirm',
                rightButtonLabel: t('identity'),
                onRight: () => {
                  openIdentifyPopup()
                }
              })
              break
            // 게임 플레이 가능한 연령이 아님
            case '47641':
              confirmPopup({
                msg: t('this age does not allow you to play games'),
                type: 'alert'
              })
              break
            // 다른 에디션 이미 보유
            case '47642':
              confirmPopup({
                msg: t('already own another edition'),
                type: 'alert'
              })
              break
            // 이미 보유한 상품
            case '47643':
              confirmPopup({
                msg: t('this product is already in stock'),
                type: 'alert'
              })
              break
            // 에디션 미보유에서 DLC 추가 시도 - TODO: 스펙 변경으로 삭제 예정
            case '47644':
              confirmPopup({
                msg: t(
                  'additional content is available after purchasing the game'
                ),
                type: 'confirm',
                rightButtonLabel: t('go to buy'),
                onRight: () => {
                  history.push(`/game/${params.game_code}`)
                }
              })
              break
            // 이미 장바구니에 추가된 상품
            case '47645':
              confirmPopup({
                msg: `${t(
                  'this product has already been added to your shopping cart'
                )}<br/>${t('would you like to go to cart?')}`,
                type: 'confirm',
                rightButtonLabel: t('go'),
                onRight: () => {
                  history.push(STORE_PATH.CART)
                }
              })
              break

            default:
              confirmPopup({
                msg: t('an error occurred'),
                type: 'alert'
              })
          }
        } else {
          // 공통 에러
          confirmPopup({
            msg: t('an error occurred'),
            type: 'alert'
          })
        }
      }
    },
    [t, addCartItem, history]
  )

  return {
    addCartItem: fetchData
  }
}

// 주문서 생성
function useCreateOrderForm() {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [createOrderForm] = useCreateOrderFormMutation()

  // 생성 요청
  const fetchData = useCallback(
    async ({ from_info, ...rest }) => {
      try {
        const res = await createOrderForm(rest).unwrap()
        if (isPurpleApp) {
          // 월렛 연동
          window.location.href = `ncmtalk://purplus?service=wallet&type=purchase&order_key=${res.order_key}`
          // 현재 열린 장바구니 웹뷰 닫기
          setTimeout(() => {
            mobileScheme.closeWebview()
          }, 100)
        } else {
          // 주문서 페이지
          history.push({
            pathname: moveOrderView({
              orderKey: res.order_key
            }),
            search: `?frm=${from_info}`
          })
        }
      } catch (error) {
        console.log(error)
        const { status, data } = error
        // 세션 만료
        if (status === 401) {
          commonSessionError()
        } else if (status === 403 && data.error) {
          switch (data.error) {
            // 유료결제 보호자 미동의
            case '47650':
              confirmPopup({
                msg: t('please complete the payment agreement first'),
                type: 'confirm',
                rightButtonLabel: t('guardian consent'),
                onRight: () => {
                  openParentAgreementPopup()
                }
              })
              break
            // 유효하지 않은 상품
            case '47651':
              confirmPopup({
                msg: t('contains products that cannot be purchased'),
                type: 'alert',
                onRight: () => {
                  // 장바구니 목록 갱신
                  dispatch(
                    purchaseQuery.endpoints.getCartList.initiate(undefined, {
                      forceRefetch: true
                    })
                  )
                }
              })
              break
            // 수량 초과 구매 불가
            case '47652':
              confirmPopup({
                msg: t('you can purchase up to', { count: 10 }),
                type: 'alert'
              })
              break
            // 함께 구매할 수 없는 상품 여러개 구매 시도 시
            case '47653':
            case '47654':
              confirmPopup({
                msg: t('there are duplicate products or components'),
                type: 'alert'
              })
              break
            // 본 게임(에디션) 미보유 상태에서 DLC상품만 단독 구매 시도 시
            case '47644':
              confirmPopup({
                msg: t('additional content is available when you own the game'),
                type: 'alert'
              })
              break
            default:
              confirmPopup({
                msg: t('an error occurred'),
                type: 'alert',
                onRight: () => {
                  // 장바구니 목록 갱신
                  dispatch(
                    purchaseQuery.endpoints.getCartList.initiate(undefined, {
                      forceRefetch: true
                    })
                  )
                }
              })
          }
        } else {
          // 공통 에러
          confirmPopup({
            msg: t('an error occurred'),
            type: 'alert',
            onRight: () => {
              // 장바구니 목록 갱신
              dispatch(
                purchaseQuery.endpoints.getCartList.initiate(undefined, {
                  forceRefetch: true
                })
              )
            }
          })
        }
      }
    },
    [t, dispatch, createOrderForm, history]
  )

  return {
    createOrderForm: fetchData
  }
}

export {
  useAddFavoriteItem,
  useDeleteFavoriteItem,
  useAddCartItem,
  // useGetOptEmail,
  useCreateOrderForm,
  // useUpdateOptEmail,
  useCheckLoginUser,
  useGetAdConsent,
  useUpdateAdConsent
}
