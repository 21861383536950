import { createSlice, current } from '@reduxjs/toolkit'

// initial state
export const initialState = {
  fullBannerWatched: {},
  isFullBannerOpen: false,
  isShowTutorial: false,
  characterDetail: JSON.parse(sessionStorage.getItem('characterDetail') || '{}')
}

// reducer Slice
const lobbySlice = createSlice({
  name: 'lobbySlice',
  initialState,
  // 생성한 액션 (동기 처리 리듀서)
  reducers: {
    setFullBannerWatched: (state, action) => {
      state.fullBannerWatched = {
        ...state.fullBannerWatched,
        [action.payload]: true
      }
    },
    setIsFullBannerOpen: (state, action) => {
      state.isFullBannerOpen = action.payload
    },
    setIsShowTutorial: (state, action) => {
      state.isShowTutorial = action.payload
    },
    setCharacterDetail: (state, action) => {
      const gameCode = action.payload.gameCode
      state.characterDetail = {
        ...state.characterDetail,
        [gameCode]: {
          error: {
            ...action.payload.error
          },
          data: {
            ...action.payload.data
          }
        }
      }
    }
  },
  // 생선한 액션 외 외부 액션 참조 (비동기 처리 리듀서)
  extraReducers: (builder) => {}
})

const { actions, reducer } = lobbySlice

// Actions 내보내기
export const lobbyActions = actions
// Selector 내보내기
export const lobbySelector = (state) => state.lobbySlice
// Reducer 내보내기
export default reducer
