import { useEffect, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

// RTK
import {
  useLazyGetNotificationListQuery,
  useLazyGetNotificationCountQuery
} from 'store/services/query/notiQuery'

// Utils
import { Config as config } from '@purple/common/utils/globalUtility'
import { getCountryCode } from '@purple/common/utils/locationUtility'
import { COUNTRY_TYPE } from '@purple/common/constants/locationTypes'

/**
 * 알림 데이터 호출
 * @description 알림 데이터 조회
 * @param {string} params {
 *  param: 목록 콤마로 구분하여 호출
 *  markAsRead: 알림 필터별 읽음 처리
 * }
 */
const useGetNotifyData = ({ param, markAsRead } = {}) => {
  const { t } = useTranslation()
  const [getNotiListTrigger, queryResult] = useLazyGetNotificationListQuery()
  const [getNotiCountTrigger] = useLazyGetNotificationCountQuery()

  const serviceList = useMemo(
    () =>
      [
        { name: t('all'), value: null },
        ...(config['notification'] && config['notification']?.serviceList
          ? config['notification']?.serviceList
          : [
              { name: 'TALK', value: 'pptalk' },
              { name: 'ON', value: 'ppon' },
              { name: 'STORE', value: 'ppstore' },
              { name: 'LOUNGE', value: 'pplounge' }
            ])
      ].filter(
        (service) =>
          (![
            COUNTRY_TYPE.JP,
            COUNTRY_TYPE.TW,
            COUNTRY_TYPE.HK,
            COUNTRY_TYPE.MO
          ].includes(getCountryCode()) ||
            service.name !== 'STORE') &&
          (![
            COUNTRY_TYPE.CZ,
            COUNTRY_TYPE.BE,
            COUNTRY_TYPE.NL,
            COUNTRY_TYPE.TR,
            COUNTRY_TYPE.RO
          ].includes(getCountryCode()) ||
            service.name !== 'ON')
      ),
    [t]
  )

  const paramsAll = useMemo(
    () =>
      serviceList
        .reduce((acc, feed) => {
          if (feed.value !== null) {
            acc.push(feed.value)
          }
          return acc
        }, [])
        .join(','),
    [serviceList]
  )

  const filter = useMemo(() => (param ? param : paramsAll), [paramsAll, param])

  useEffect(() => {
    const getNotifyProcess = async () => {
      try {
        const isInitialized = param === undefined
        if (!isInitialized) {
          const res = await getNotiListTrigger({
            filter,
            markAsRead
          }).unwrap()
        }
        const count = await getNotiCountTrigger(
          {
            filter: paramsAll,
            param
          },
          isInitialized
        ).unwrap()
      } catch (error) {}
    }
    if (!!filter) {
      getNotifyProcess()
    }
  }, [
    filter,
    markAsRead,
    paramsAll,
    getNotiListTrigger,
    getNotiCountTrigger,
    param
  ])

  return {
    filter,
    serviceList,
    getNotiListTrigger,
    queryResult
  }
}

export { useGetNotifyData }
