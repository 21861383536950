import { purpleApi } from 'store/services/purpleApi'
import { commonSessionError } from 'utils/appUtility'
import { AD_CONSENT_STATUS } from 'constants/storeTypes'

export const purchaseQuery = purpleApi.injectEndpoints({
  endpoints: (build) => ({
    // 상품 가격 정보 조회
    getPriceInfo: build.query({
      query: (params) => ({
        url: '/api/purchase/goods_price',
        method: 'GET',
        params
      }),
      transformResponse: (response, meta, arg) => {
        return response.price_list.reduce((acc, item) => {
          acc[item.id] = item
          return acc
        }, {})
      }
    }),

    // 장바구니 목록 조회
    getCartList: build.query({
      query: () => ({
        url: '/api/purchase/service/cart/list',
        method: 'GET'
      })
    }),
    // 장바구니 추가
    addCartItem: build.mutation({
      query: (params) => ({
        url: '/api/purchase/service/cart',
        method: 'POST',
        body: params
      }),
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          // 장바구니 목록 카운트 갱신
          dispatch(
            purchaseQuery.util.updateQueryData(
              'getCartList',
              undefined,
              (draft) => {
                draft.total_count = draft.total_count + 1
              }
            )
          )
        } catch (e) {
          // console.log(e)
        }
      }
    }),
    // 장바구니 삭제
    deleteCartItem: build.mutation({
      query: (params) => ({
        url: '/api/purchase/service/cart',
        method: 'DELETE',
        params
      }),
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // 장바구니 목록 갱신
          dispatch(
            purchaseQuery.util.updateQueryData(
              'getCartList',
              undefined,
              (draft) => {
                const targetIdx = draft.list.findIndex((item) => {
                  return item.id === data.id
                })
                draft.list.splice(targetIdx, 1)
                draft.total_count = draft.total_count - 1
              }
            )
          )
        } catch (response) {
          const { status } = response?.error
          if (status === 401) {
            commonSessionError()
          }
        }
      }
    }),
    // 장바구니 모두 삭제
    deleteAllCartItem: build.mutation({
      query: () => ({
        url: '/api/purchase/service/cart/all',
        method: 'DELETE'
      }),
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          // 장바구니 목록 갱신
          dispatch(
            purchaseQuery.util.updateQueryData(
              'getCartList',
              undefined,
              (draft) => {
                draft.list = []
                draft.total_count = 0
              }
            )
          )
        } catch (response) {
          const { status } = response?.error
          if (status === 401) {
            commonSessionError()
          }
        }
      }
    }),

    // 찜 목록 조회
    getFavoriteList: build.query({
      query: (params) => ({
        url: '/api/purchase/service/wishlist/list',
        method: 'GET',
        params: params
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName
      }
    }),
    // 찜 추가
    addFavoriteItem: build.mutation({
      query: (params) => ({
        url: '/api/purchase/service/wishlist',
        method: 'POST',
        body: params
      }),
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // 찜 여부 갱신
          dispatch(
            purchaseQuery.util.updateQueryData(
              'getFavoriteExist',
              { id: data.id },
              (draft) => {
                draft.result = true
              }
            )
          )
          // 찜 목록 카운트 갱신
          dispatch(
            purchaseQuery.util.updateQueryData(
              'getFavoriteList',
              undefined,
              (draft) => {
                draft.total_count = draft.total_count + 1
              }
            )
          )
        } catch (response) {}
      }
    }),
    // 찜 여부 조회
    getFavoriteExist: build.query({
      query: (params) => ({
        url: '/api/purchase/service/wishlist/exist',
        method: 'GET',
        params
      })
    }),
    // 찜 삭제
    deleteFavoriteItem: build.mutation({
      query: (params) => ({
        url: '/api/purchase/service/wishlist',
        method: 'DELETE',
        params
      }),
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // 찜 목록 갱신
          dispatch(
            purchaseQuery.util.updateQueryData(
              'getFavoriteList',
              undefined,
              (draft) => {
                const targetIdx = draft.list.findIndex((item) => {
                  return item.id === data.id
                })
                draft.list.splice(targetIdx, 1)
                draft.total_count = draft.total_count - 1
              }
            )
          )
          // 찜 여부 갱신
          dispatch(
            purchaseQuery.util.updateQueryData(
              'getFavoriteExist',
              { id: data.id },
              (draft) => {
                draft.result = false
              }
            )
          )
        } catch (response) {}
      }
    }),
    // 찜 모두 삭제
    deleteAllFavoriteItem: build.mutation({
      query: () => ({
        url: '/api/purchase/service/wishlist/all',
        method: 'DELETE'
      }),
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // 찜 목록 갱신
          dispatch(
            purchaseQuery.util.updateQueryData(
              'getFavoriteList',
              undefined,
              (draft) => {
                draft.list = []
                draft.total_count = 0
              }
            )
          )
        } catch (response) {}
      }
    }),

    // 주문서 생성
    createOrderForm: build.mutation({
      query: (params) => ({
        url: '/api/purchase/order/create',
        method: 'POST',
        body: params
      })
    }),
    // 이메일 광고 수신 동의 여부 조회
    getAdConsent: build.query({
      query: () => ({
        url: '/api/purchase/service/ad_consent',
        method: 'GET'
      })
    }),
    // 이메일 광고 수신 동의
    updateAdConsent: build.mutation({
      query: (params) => ({
        url: '/api/purchase/service/ad_consent',
        method: 'POST',
        body: params
      }),
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          // 동의 여부 값 갱신
          dispatch(
            purchaseQuery.util.updateQueryData(
              'getAdConsent',
              undefined,
              (draft) => {
                draft.status = params.agree
                  ? AD_CONSENT_STATUS.AGREE
                  : AD_CONSENT_STATUS.REJECT
              }
            )
          )
        } catch (response) {
          const { status } = response?.error
          if (status === 401) {
            commonSessionError()
          }
        }
      }
    })
  })
})

export const {
  useGetPriceInfoQuery,

  useGetCartListQuery,
  useLazyGetCartListQuery,
  useAddCartItemMutation,
  useDeleteCartItemMutation,
  useDeleteAllCartItemMutation,

  useGetFavoriteListQuery,
  useLazyGetFavoriteListQuery,
  useGetFavoriteExistQuery,
  useAddFavoriteItemMutation,
  useDeleteFavoriteItemMutation,
  useDeleteAllFavoriteItemMutation,

  useCreateOrderFormMutation,
  useGetAdConsentQuery,
  useLazyGetAdConsentQuery,
  useUpdateAdConsentMutation
} = purchaseQuery
