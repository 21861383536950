import axios from 'axios'
import { Config as config } from '@purple/common/utils/globalUtility'
import { getToken } from '@purple/common/utils/tokenUtility'
import {
  setRamdomTid,
  getLocale,
  getDeviceId
} from '@purple/common/utils/commonUtility'
import { getCountryCode } from '@purple/common/utils/locationUtility'
import { configPreGameCodeList } from '@purple/common/utils/gameUtility'
import { sendErrorReport } from '@purple/common/utils/errorReportUtility'

// CONSTANTS
import { TOKENKEY_TYPE } from 'constants/tokenTypes'

let requestInfo = null
// const deviceId = window.NGP_VARS
//   ? window.NGP_VARS.purple.deviceId
//   : setRamdomTid()
let instance = axios.create({
  withCredentials: true,
  baseURL: config['contentsAdmin.api.url'] || '',
  headers: {
    GateType: 'ApiGate',
    'Lime-API-Version': config.apiVersion || 140,
    'Lime-Device-Id': getDeviceId(),
    'Lime-Locale': getLocale(),
    'Lime-Preservice-Game-Codes': configPreGameCodeList(),
    'X-CountryCode': getCountryCode()
  },
  timeout: config.apiTimeout || 5000
})

instance.interceptors.request.use(
  (config) => {
    config.headers['Lime-Trace-Id'] = setRamdomTid()
    config.headers.Authorization = getToken(TOKENKEY_TYPE.LIME_JWT)
    requestInfo = config
    return config
  },
  (error) => {
    // Do something with request error
    // console.log(error); // for debug
    Promise.reject(error)
  }
)

instance.interceptors.response.use(
  (response) => {
    return Promise.resolve(response)
  },
  (error) => {
    sendErrorReport(error, requestInfo)
    return Promise.reject(error)
  }
)

export default instance
