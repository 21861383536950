const op = 'https://o' + 'p'
const sb = 'https://s' + 'b'
const rc = 'https://r' + 'c'

//게임별 공홈 도메인
export const OFFICIALHOME_DOMAIN = {
  sandbox: {
    TRICKSTERM: `${sb}-tricksterm.plaync.com`,
    LMS: `${sb}-lineagem.plaync.com`,
    L2M: `${sb}-lineage2m.plaync.com`,
    L2MNU: `${sb}-lineage2m.plaync.com/naeu`,
    L2MRU: `${sb}-lineage2m.plaync.com/ru`,
    L2MNCT: `${sb}-tw.ncsoft.com/lineage2m`,
    L2MNCJ: `${sb}-ncj-lineage2m.plaync.com`,
    LINW: `${sb}-lineagew.plaync.com`,
    H3: `${sb}-h3.plaync.com`,
    BNS2: `${sb}-bns2.plaync.com`,
    TL: 'https://tl.sb.plaync.com',
    LIN: 'https://lineage.sb.plaync.com',
    LIN2: 'https://lineage2.sb.plaync.com',
    AION: 'https://aion.sb.plaync.com',
    BNS: 'https://bns.sb.plaync.com',
    NLW: 'https://journey.sb.plaync.com'
  },
  rc: {
    TRICKSTERM: `${rc}-tricksterm.plaync.com`,
    LMS: `${rc}-lineagem.plaync.com`,
    L2M: `${rc}-lineage2m.plaync.com`,
    L2MNU: `${rc}-lineage2m.plaync.com/naeu`,
    L2MRU: `${rc}-lineage2m.plaync.com/ru`,
    L2MNCT: `${rc}-tw.ncsoft.com/lineage2m`,
    L2MNCJ: `${rc}-lineage2m.ncsoft.jp`,
    LINW: `${rc}-lineagew.plaync.com`,
    H3: `${rc}-h3.plaync.com`,
    BNS2: `${rc}-bns2.plaync.com`,
    TL: `${rc}-tl.plaync.com`,
    LIN: `${rc}-lineage.plaync.com`,
    LIN2: `${rc}-lineage2.plaync.com`,
    AION: `${rc}-aion.plaync.com`,
    BNS: `${rc}-bns.plaync.com`,
    NLW: `${rc}-journey.plaync.com`
  },
  live: {
    TRICKSTERM: 'https://tricksterm.plaync.com',
    LMS: 'https://lineagem.plaync.com',
    L2M: 'https://lineage2m.plaync.com',
    L2MNU: 'https://lineage2m.plaync.com/naeu',
    L2MRU: 'https://lineage2m.plaync.com/ru',
    L2MNCT: 'https://tw.ncsoft.com/lineage2m',
    L2MNCJ: 'https://lineage2m.ncsoft.jp',
    LINW: 'https://lineagew.plaync.com',
    H3: 'https://h3.plaync.com',
    BNS2: 'https://bns2.plaync.com',
    TL: 'https://tl.plaync.com',
    LIN: 'https://lineage.plaync.com',
    LIN2: 'https://lineage2.plaync.com',
    AION: 'https://aion.plaync.com',
    BNS: 'https://bns.plaync.com',
    NLW: 'https://journey.plaync.com'
  }
}

export const OFFICIALHOME_PATH = {
  TRICKSTERM: '/my/profile/character',
  LMS: '/my/profile/character',
  L2M: '/my/profile/character',
  L2MNU: '/my/profile/character',
  L2MRU: '/my/profile/character',
  L2MNCT: '/my/profile/character',
  L2MNCJ: '/my/profile/character',
  LINW: '/my/profile/character',
  H3: '/my/profile/character',
  BNS2: '/my/profile/character',
  TL: '/ko-kr/my/profile/character', // TODO: TL 글로벌 출시 -> 국가별 분기 처리필요
  LIN: '/my/character',
  LIN2: '/character/home',
  AION: '/characters/home',
  BNS: '/world/character',
  NLW: '/my/profile/character'
}
