import React, { useMemo, useCallback, useState, useEffect } from 'react'

// RTK
import {
  useGetArticleListQuery,
  useLazyGetArticleListQuery,
  useGetTopNoticeListQuery,
  useLazyGetTopNoticeQuery
} from 'store/services/query/boardQuery'

// Hooks
import useSearchParams from 'hooks/common/useSearchParams'

const useGetBoardList = ({
  serviceAlias = 'purple',
  boardAlias,
  categoryAlias,
  refetchOnMountOrArgChange = false
}) => {
  const searchParams = useSearchParams()
  const query = useMemo(() => searchParams.get('query'), [searchParams])
  const category = useMemo(
    () => categoryAlias || searchParams.get('category') || null,

    [categoryAlias, searchParams]
  )

  // 리스트 초기 호출
  const { data = {}, ...rest } = useGetArticleListQuery(
    {
      serviceAlias,
      boardAlias,
      categoryAlias,
      params: {
        more_size: 20,
        search_type: query ? 'title,contents' : undefined,
        query: query || undefined,
        category: category || undefined
      }
    },
    { refetchOnMountOrArgChange }
  )
  const [getListTrigger] = useLazyGetArticleListQuery()

  // 리스트 더보기 호출
  const handleMoreList = useCallback(
    async (prevId) => {
      try {
        await getListTrigger({
          serviceAlias,
          boardAlias,
          categoryAlias,
          params: {
            more_size: 20,
            search_type: query ? 'title,contents' : undefined,
            query: query || undefined,
            category: category || undefined,
            previous_article_id: prevId || undefined
          }
        }).unwrap()
      } catch (error) {
        console.log(error)
      }
    },
    [getListTrigger, category, query, serviceAlias, boardAlias, categoryAlias]
  )

  return {
    handleMoreList,
    listData: data,
    query,
    ...rest
  }
}

const useGetTopNoticeList = ({
  serviceAlias = 'purple',
  boardAlias,
  categoryAlias,
  refetchOnMountOrArgChange = false,
  skip = false
}) => {
  const searchParams = useSearchParams()
  const category = useMemo(
    () =>
      searchParams.get('category')
        ? searchParams.get('category')
        : categoryAlias,
    [categoryAlias, searchParams]
  )

  // 리스트 초기 호출
  const { data = {}, ...rest } = useGetTopNoticeListQuery(
    {
      boardAlias: boardAlias,
      params: {
        count: 5,
        category: category || undefined
      }
    },
    { refetchOnMountOrArgChange, skip }
  )
  // const [getListTrigger] = useLazyGetTopNoticeQuery()

  return {
    topNoticeData: data,
    ...rest
  }
}

export { useGetBoardList, useGetTopNoticeList }
