import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import { Config as config } from '@purple/common/utils/globalUtility'

const baseQuery = fetchBaseQuery({
  baseUrl: config['noti.api.url'] || 'https://noti.plaync.com',
  prepareHeaders: (headers, { getState }) => {
    // headers.set('GateType', 'ApiGate')
    // headers.set('X-CountryCode', getCountryCode())
    headers.set('Accept', 'application/json, text/plain, */*')
    return headers
  },
  credentials: 'include',
  timeout: config.apiTimeout || 5000
})

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 })

export const notiApi = createApi({
  reducerPath: 'notiApi',
  baseQuery: baseQueryWithRetry,
  endpoints: () => ({})
})
