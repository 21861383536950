/**
  [TYPE CODE]
  TRICKSTERM_GAMA_DATA [트릭스터M] 게임데이터 점검 시간
  BNS2_EVENT [B2] 로비 > 이벤트 점검 시간
  BNS2_NOTICE [B2] 로비 > 소식 점검 시간
  H3_GAME_DATA 	[H3] 게임데이터 점검 시간
  BNS2_GAME_DATA [B2] 로비 > 게임데이터영역 점검 시간
  LINW_GAME_DATA 	[LinW] 로비 > 게임데이터영역 점검 시간
  LINW_BOARD [게시판] LinW 게시판 점검 시간
  BNS2_BOARD [게시판] B2 게시판 점검 시간
  H3_BOARD [게시판] H3 게시판 점검 시간
  L2M_BOARD [게시판] L2M 게시판 점검 시간
  L2MNU_BOARD [게시판] L2M NU 게시판 점검 시간
  L2MRU_BOARD [게시판] L2M RU 게시판 점검 시간
  LM_BOARD [게시판] LM 게시판 점검 시간
  TRM_BOARD [게시판] 트릭스터M 게시판 점검 시간
  PURPLE_BOARD [게시판] purple(ngp) 게시판 점검 시간
 */
export const MAINTENENCE_TYPE = {
  NLW_GAME_DATA: 'gamedata.nlw',
  NLWTW_GAME_DATA: 'gamedata.nlwtw',
  NLWJP_GAME_DATA: 'gamedata.nlwjp',
  NLWUS_GAME_DATA: 'gamedata.nlwus',

  TL_GAME_DATA: 'gamedata.tl', // 공홈 점검 시 설정
  TLSG_GAME_DATA: 'gamedata.tlsg', // 공홈 점검 시 설정
  HOYEON_GAME_DATA: 'gamedata.hoyeon',
  HOYEONTW_GAME_DATA: 'gamedata.hoyeontw',
  HOYOEONJP_GAME_DATA: 'gamedata.hoyeonjp',
  LIN_GAME_DATA: 'gamedata.lin',
  LIN2_GAME_DATA: 'gamedata.lin2',
  BNS_GAME_DATA: 'gamedata.bns',
  AION_GAME_DATA: 'gamedata.aion',
  LOVEBEAT_GAME_DATA: 'gamedata.lovebeat',
  LMS_GAME_DATA: 'gamedata.lms',
  L2M_GAME_DATA: 'gamedata.l2m',
  LINW_GAME_DATA: 'gamedata.linw',
  BNS2_GAME_DATA: 'gamedata.bns2',

  L2MRU_GAME_DATA: 'gamedata.l2mru',
  L2MNU_GAME_DATA: 'gamedata.l2mnu',

  LMJ_GAME_DATA: 'gamedata.lmj',
  LINNCJ_GAME_DATA: 'gamedata.linncj',
  LIN2NCJ_GAME_DATA: 'gamedata.lin2ncj',
  AIONNCJ_GAME_DATA: 'gamedata.aionncj',
  BNSNCJ_GAME_DATA: 'gamedata.bnsncj',
  JANRYUMONJP_GAME_DATA: 'gamedata.janryumonJP',
  L2MNCJ_GAME_DATA: 'gamedata.l2mncj',
  BNS2JP_GAME_DATA: 'gamedata.bns2jp',

  L2MNCT_GAME_DATA: 'gamedata.l2mnct',
  LIN2NCT_GAME_DATA: 'gamedata.lin2nct',
  AIONNCT_GAME_DATA: 'gamedata.aionnct',
  BNSNCT_GAME_DATA: 'gamedata.bnsnct',
  BNS2TW_GAME_DATA: 'gamedata.bns2tw',

  AIONNCW_GAME_DATA: 'gamedata.aionncw',
  BATTLECRUSH_GAME_DATA: 'gamedata.battlecrush',
  LIN2NCW_GAME_DATA: 'gamedata.lin2ncw',
  BNSNCW_GAME_DATA: 'gamedata.bnsncw',

  PURPLE_BOARD: 'community.purple',

  NLW_BRAND_HOME: 'brandhome.nlw',
  NLWTW_BRAND_HOME: 'brandhome.nlwtw',
  NLWJP_BRAND_HOME: 'brandhome.nlwjp',

  TL_BRAND_HOME: 'brandhome.tl', // 공홈 점검 시 설정
  TLSG_BRAND_HOME: 'brandhome.tlsg', // 공홈 점검 시 설정
  HOYEON_BRAND_HOME: 'brandhome.hoyeon',
  HOYEONTW_BRAND_HOME: 'brandhome.hoyeontw',
  HOYOEONJP_BRAND_HOME: 'brandhome.hoyeonjp',
  LIN_BRAND_HOME: 'brandhome.lin',
  LIN2_BRAND_HOME: 'brandhome.lin2',
  BNS_BRAND_HOME: 'brandhome.bns',
  AION_BRAND_HOME: 'brandhome.aion',
  LOVEBEAT_BRAND_HOME: 'brandhome.lovebeat',
  LMS_BRAND_HOME: 'brandhome.lms',
  L2M_BRAND_HOME: 'brandhome.l2m',
  LINW_BRAND_HOME: 'brandhome.linw',
  BNS2_BRAND_HOME: 'brandhome.bns2',
  BATTLECRUSH_BRAND_HOME: 'brandhome.battlecrush',
  L2MRU_BRAND_HOME: 'brandhome.l2mru',
  L2MNU_BRAND_HOME: 'brandhome.l2mnu',

  LMJ_BRAND_HOME: 'brandhome.lmj',
  LINNCJ_BRAND_HOME: 'brandhome.linncj',
  LIN2NCJ_BRAND_HOME: 'brandhome.lin2ncj',
  AIONNCJ_BRAND_HOME: 'brandhome.aionncj',
  BNSNCJ_BRAND_HOME: 'brandhome.bnsncj',
  JANRYUMONJP_BRAND_HOME: 'brandhome.janryumonJP',
  L2MNCJ_BRAND_HOME: 'brandhome.l2mncj',
  BNS2JP_BRAND_HOME: 'brandhome.bns2jp',

  L2MNCT_BRAND_HOME: 'brandhome.l2mnct',
  LIN2NCT_BRAND_HOME: 'brandhome.lin2nct',
  AIONNCT_BRAND_HOME: 'brandhome.aionnct',
  BNSNCT_BRAND_HOME: 'brandhome.bnsnct',
  BNS2TW_BRAND_HOME: 'brandhome.bns2tw',

  AIONNCW_BRAND_HOME: 'brandhome.aionncw',
  LIN2NCW_BRAND_HOME: 'brandhome.lin2ncw',
  BNSNCW_BRAND_HOME: 'brandhome.bnsncw',

  BNS2_EVENT: 'lobby.b2.event', //로비 전환 후 삭제
  BNS2_NOTICE: 'lobby.b2.notice', //로비 전환 후 삭제
  NSHOP: 'nshop',
  STORE: 'store'
}

/*
  [TYPE CODE]
  NORMAL: 정상 상태
  MAINTENANCE: 점검 상태
  ERROR: 에러 상태
 */
export const PAGE_STATUS = {
  NORMAL: 'NORMAL',
  MAINTENANCE: 'MAINTENANCE',
  ERROR: 'ERROR'
}

export const SUPPORT_PAGE_URL = 'https://help.plaync.com/faq/cmntstandard'

/*
  [TYPE CODE]
  INHOUSE: 사내
  SIE: 플랫폼 - sie
  NONE: 체크되지 않은 IP
 */
export const NETWORK_TYPE = {
  INHOUSE: 'inhouse',
  SIE: 'SIE',
  NONE: 'NONE'
}
